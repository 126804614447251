export const customGetVanityInfoByVanityName = /* GraphQL */ `
  query CustomGetVanityInfoByVanityName($vanityName: String!) {
    vanityInfoByVanityName(vanityName: $vanityName) {
      items {
        id
        createdAt
        vanityName
        advisorId
        advisor {
          id
          companyName
          companyUrl
          companyLogo {
            bucket
            region
            key
            __typename
          }
          companyAddress
          address {
            streetAddress1
            streetAddress2
            city
            state
            postalCode
            __typename
          }
          firstName
          lastName
          email
          credentials
          phone
          avatar {
            bucket
            region
            key
            __typename
          }
          createdAt
          updatedAt
          showAdvisorCard
          prospectCriteria
          vanityNames {
            items {
              id
              createdAt
              vanityName
              advisorId
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          notificationEmails
          disclosure
          bypassCrm
          customReportCtaUrl
          videoUrl
          scoreReportTemplateId
          collectContactPostSurvey
          organizationId
          #   organization {
          #     id
          #     name
          #     routePathName
          #     logo {
          #       bucket
          #       region
          #       key
          #       __typename
          #     }
          #     active
          #     accessCode
          #     url
          #     address {
          #       streetAddress1
          #       streetAddress2
          #       city
          #       state
          #       postalCode
          #       __typename
          #     }
          #     phone
          #     createdAt
          #     updatedAt
          #     resources {
          #       items {
          #         id
          #         createdAt
          #         updatedAt
          #         organizationId
          #         displayOrder
          #         title
          #         subTitle
          #         mediaType
          #         urlTarget
          #         videoId
          #         __typename
          #       }
          #       nextToken
          #       __typename
          #     }
          #     promos {
          #       items {
          #         promoCode
          #         organizationId
          #         createdAt
          #         updatedAt
          #         description
          #         active
          #         duration
          #         endDate
          #         product_id
          #         discount
          #         price_id
          #         __typename
          #       }
          #       nextToken
          #       __typename
          #     }
          #     __typename
          #   }
          acceptRegistrationTerms
          promo_code
          payment_method
          stripe
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
